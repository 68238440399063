<template>
  <div>
    <type-item
      :dataParm="dataParm"
      :config="serverListConfig"
      @dataChange="dataChange"
      key="choosed">
    </type-item>
    <fm-modal v-model="openModel" v-if="openModel" width="800px">
      <type-item
        :config="serverListConfigChoose"
        key="openModel"
        @checked="checked">
      </type-item>
      <div class="no-data">
        <fm-btn @click="sub">
          确定
        </fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import TypeItem from '@/components/datainfo/TypeItem'

import {
  serverList
} from '@/data_conf/server'

export default {
  components: {
    TypeItem
  },
  props: {
    step: {
      type: Object
    }
  },
  data () {
    return {
      openModel: false,
      chooseData: [],
      chooseData2: [],
      serverListConfig: serverList({
        listMenus: [],
        titleMenus: [{
          key: 'choose',
          label: '选择',
          dealFun: () => {
            if (this.step.status !== 'ok') {
              this.chooseData2 = []
              this.openModel = true
            }
          }
        }]
      }),
      serverListConfigChoose: serverList({childParm: {defaultOpen: false, showCheckbox: true}}),
    }
  },
  computed: {
    dataParm () {
      return {
        ids: this.chooseData.map(v => v.id)
      }
    }
  },
  created () {
    if (this.chooseData.length === 0) {
      this.$nextTick(() => {
        this.openModel = true
      })
    }
  },
  methods: {
    dataChange (data) {
      if (data.length > 0) {
        this.$emit('serverListChange', data)
      }
    },
    sub () {
      this.openModel = false
      this.chooseData = this.chooseData2.map(v => v)
      this.$emit('serverListChange', this.chooseData)
    },
    checked (data) {
      this.chooseData2 = this.chooseData2.filter(v => v.id !== data.data.id)
      if (data.checked) {
        this.chooseData2.push(data.data)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.no-data {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>