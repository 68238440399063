<template>
  <div class="sys-instance">
    <div class="type-item-title">
      <div class="title-text">应用配置</div>
    </div>
    <div style="margin: 0 2rem;">
    <fm-table
      :column-list="columnList"
      :data-list="appVersionServerList"
      @tableAction="tableAction"
      :auto-height="true"
      :table-actions="tableActions">
    </fm-table>
    </div>
    <fm-form-dialog
      form-title="选择服务器"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      form-width="800px"
      label-alone
      label-align="left"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <input-file-update
      :open-dialog.sync="openDialogFile"
      @chooseFile="chooseFile"
      @handleClose="openDialogFile = false">
    </input-file-update>
  </div>
</template>

<script>
export default {
  props: {
    appVersionList: {
      type: Array
    },
    serverList: {
      type: Array
    },
    step: {
      type: Object
    }
  },
  watch: {
    serverList () {
      this.dealDataServer()
    },
    appVersionList () {
      this.dealData()
    },
  },
  data () {
    return {
      openDialogFile: false,
      openDialog: false,
      appVersionServerList: [],
      chooseData: null
    }
  },
  created () {
    this.dealData()
  },
  computed: {
    tableActions () {
      let data = this.serverList.length > 1 ? [{
        key: 'file',
        label: '部署文件'
      },
      {
        key: 'server',
        label: '选择服务器'
      }] : [{
        key: 'file',
        label: '部署文件'
      }]
      return this.step.status === 'ok' ? [] : data
    },
    columnList () {
      return [{
        field: 'appName',
        title: '应用'
      },
      {
        field: 'versionNumber',
        title: '版本'
      },
      {
        field: 'serverName',
        title: '部署服务器'
      },
      {
        field: 'restartTypeName',
        title: '部署类型'
      },
      {
        field: 'restartFileName',
        title: '部署文件'
      }]
    },
    formParms () {
      return [{
        type: 'select',
        label: '部署服务器',
        key: 'serverId',
        selectDatas: this.serverList.map((v) => {
          return {
            key: v.id,
            label: v.name
          }
        }),
        check: {
          required: true
        }
      }]
    }
  },
  methods: {
    chooseFile (file) {
      let i = 0
      while (i < this.appVersionServerList.length) {
        if (this.appVersionServerList[i].appVersionConfigId === this.chooseData.appVersionConfigId) {
          break
        }
        i += 1
      }
      this.appVersionServerList[i].restartFileName = file.name
      this.appVersionServerList[i].restartFile = file
      this.openDialogFile = false
      this.$emit('appVersionServerListChange', this.appVersionServerList)
    },
    formSubmit (data) {
      let i = 0
      while (i < this.appVersionServerList.length) {
        if (this.appVersionServerList[i].appVersionConfigId === this.chooseData.appVersionConfigId) {
          break
        }
        i += 1
      }
      this.appVersionServerList[i].serverId = data.serverId
      this.appVersionServerList[i].server = this.serverList.find(v => v.id === data.serverId)
      this.appVersionServerList[i].serverName = this.serverList.find(v => v.id === data.serverId).name
      this.openDialog = false
      this.$emit('appVersionServerListChange', this.appVersionServerList)
    },
    tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'server') {
        this.openDialog = true
      } else {
        this.openDialogFile = true
      }
    },
    dealDataServer () {
      this.appVersionServerList.forEach((item) => {
        item.serverId = this.serverList[0].id
        item.serve = this.serverList[0]
        item.serverName = this.serverList[0].name
      })
      this.$emit('appVersionServerListChange', this.appVersionServerList)
    },
    dealData () {
      this.appVersionServerList = []
      this.appVersionList.forEach((item) => {
        item.appVersionConfig.forEach((item2) => {
          let newItem = {
            serverId: this.serverList[0].id,
            server: this.serverList[0],
            serverName: this.serverList[0].name,
            appVersionId: item.id,
            appVersion: item,
            appName: item.appName,
            versionNumber: item.versionNumber,
            appVersionConfig: item2,
            restartTypeName: item2.restartTypeName,
            appVersionConfigId: item2.id,
            restartFileName: '',
            restartFile: null
          }
          this.appVersionServerList.push(newItem)
        })
      })
      this.$emit('appVersionServerListChange', this.appVersionServerList)
    }
  }
}
</script>

<style lang="less" scoped>
.type-item-title {
  display: flex;
  padding: 0 1.8rem;
  font-size: 1.1rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  color: #1890ff;
}
.title-menu {
  display: none;
}
.type-item-title:hover {
  .title-menu {
    display: block;
  }
}
</style>