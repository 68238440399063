<template>
  <div class="sys-instance">
    <div class="type-item-title">
      <div class="title-text">基础信息</div>
      <div class="title-menu">
        <fm-btn
          v-if="step.status !== 'ok'"
          class="hover-show-btn"
          size="mini"
          @click="add">填写
        </fm-btn>
      </div>
    </div>
    <text-info :data="sysInstance" :config="config"></text-info>
    <fm-form-dialog
      form-title="基础信息"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="sysInstance"
      form-width="800px"
      label-alone
      label-align="left"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TextInfo from '@/components/datainfo/TextInfo'

export default {
  components: {
    TextInfo
  },
  props: {
    sysVersion: {
      type: Object
    },
    step: {
      type: Object
    }
  },
  data () {
    return {
      openDialog: false,
      sysInstance: null,
      config: {
        infoParm: [{
          field: 'user',
          label: '使用方'
        },
        {
          field: 'type',
          label: '类型'
        },
        {
          field: 'description',
          label: '说明'
        }]
      }
    }
  },
  watch: {
    sysInstance () {
      this.$emit('sysInstanceChange', this.sysInstance)
    }
  },
  created () {
    if (!this.sysInstance) {
      this.openDialog = true
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'input',
        label: '使用方',
        key: 'user',
        check: {
          required: true
        }
      },
      {
        type: 'select',
        label: '类型',
        key: 'type',
        selectDatas: [{'key': '正式环境', 'label': '正式环境'}, {'key': '测试环境', 'label': '测试环境'}, {'key': '开发环境', 'label': '开发环境'}, {'key': 'DEMO', 'label': 'DEMO'}, {'key': '其他', 'label': '其他'}]
      },
      {
        type: 'input',
        label: '说明',
        key: 'description'
      }]
    }
  },
  methods: {
    formSubmit (parm) {
      this.sysInstance = Object.assign({}, parm, {
        sysId: this.sysVersion.sysId,
        sysVersionId: this.sysVersion.id
      })
      this.openDialog = false
    },
    add () {
      this.openDialog = true
    }
  }
}
</script>

<style lang="less" scoped>
.type-item-title {
  display: flex;
  padding: 0 1.8rem;
  font-size: 1.1rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  color: #1890ff;
}
.title-menu {
  display: none;
}
.type-item-title:hover {
  .title-menu {
    display: block;
  }
}
</style>