<template>
  <div class="deploy-c">
    <div class="type-item-title">
      <div class="title-text">部署</div>
    </div>
    <div class="deploy-info" v-if="status.length > 0">
      <div v-for="(item, index) in status" :key="index">{{item}}</div>
      <div v-if="deployIng">{{deployIngText}}</div>
    </div>
    <div class="no-data">
      <fm-btn @click="deploy" v-if="!deployIng">
        开始部署
      </fm-btn>
    </div>
  </div>
</template>

<script>
import {
  appServiceConfigRequest,
  serverAppInstallRequest,
  sysInstanceRequest,
  appServiceRequest,
  deployRequest
} from '@/api'

export default {
  props: {
    appVersionList: {
      type: Array
    },
    serverList: {
      type: Array
    },
    appVersionServerList: {
      type: Array
    },
    sysInstance: {
      type: Object
    }
  },
  data () {
    return {
      softInstallData: [],
      newSysInstance: null,
      appServiceList: [],
      appServiceConfigList: [],
      deployIng: false,
      status: [],
      deployIngText: ''
    }
  },
  methods: {
    updateDeployIngText () {
      setTimeout(() => {
        this.deployIngText = this.deployIngText.length < 10 ? this.deployIngText += '.' : ''
        if (this.deployIng) {
          this.updateDeployIngText()
        }
      }, 300)
    },
    async deploy () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '是否开始部署，开始后各信息将无法调整?'})
      if (!result) {
        return
      }
      this.$emit('editOver')
      this.deployIngText = ''
      this.deployIng = true
      this.updateDeployIngText()
      try {
        this.status = []
        await this.addData()
        await this.installServerSoft()
        await this.appServiceStart()
        this.status.push('部署完成')
        this.$emit('deployEnd', {
          success: true
        })
      } catch (error) {
        this.status.push('部署失败: ' + error)
        this.$notify({
          title: '系统提示',
          message: error,
          type: 'warning'
        })
        this.deployIng = false
        this.$emit('deployEnd', {
          success: false,
          msg: error
        })
      }
      this.deployIng = false
    },
    async addData () {
      this.status.push('新增数据')
      // 新建系统实例
      this.status.push('新增应用系统')
      this.newSysInstance = await sysInstanceRequest.add(this.sysInstance)
      this.status.push('新增应用系统完成')
      // 新建服务
      this.status.push('新增服务')
      let appServiceList = []
      let appVersionIds = []
      let i = 0
      while (i < this.appVersionServerList.length) {
        if (!appVersionIds.includes(this.appVersionServerList[i].appVersionId)) {
          let parm = {
            sysInstanceId: this.newSysInstance.id,
            serverId: this.appVersionServerList[i].serverId,
            appVersionId: this.appVersionServerList[i].appVersionId
          }
          appServiceList.push(appServiceRequest.add(parm))
          appVersionIds.push(this.appVersionServerList[i].appVersionId)
        }
        i += 1
      }
      this.appServiceList = await Promise.all(appServiceList)
      this.status.push('新增服务完成')
      this.status.push('新增服务配置')
      let appServiceConfigList = []
      i = 0
      while (i < this.appVersionServerList.length) {
        let parm = Object.assign({}, this.appVersionServerList[i].appVersionConfig, {
          id: null,
          appVersionConfigId: this.appVersionServerList[i].appVersionConfig.id,
          appServiceId: this.appServiceList.find(v => v.appVersionId === this.appVersionServerList[i].appVersionId).id
        })
        appServiceConfigList.push(appServiceConfigRequest.add(parm))
        i += 1
      }
      this.appServiceConfigList = await Promise.all(appServiceConfigList)
      this.appServiceConfigList.forEach((item) => {
        let appVersionServer = this.appVersionServerList.find(v => v.appVersionConfig.id === item.appVersionConfigId)
        item.file = appVersionServer.restartFile
        item.appName = appVersionServer.appName
        item.restartTypeName = appVersionServer.restartTypeName
      })
      this.status.push('新增服务配置完成')
    },
    async installServerSoft () {
      this.status.push('软件安装')
      this.softInstallData = []
      let appVersionMap = {}
      this.appVersionList.forEach((v) => {
        appVersionMap[v.id] = v
      })
      try {
        this.appVersionServerList.forEach((v) => {
          appVersionMap[v.appVersionId].soft.forEach((v4) => {
            if (!v4.softId || !v4.installTypeId) {
              this.status.push(v4.softName + '软件无安装配置')
              throw(new Error(v4.softName + '软件无安装配置'))
            }
            this.softInstallData.push({
              serverId: v.serverId,
              installTypeId: v4.installTypeId,
              softName: v4.softName,
              softVersion: v4.softVersion
            })
          })
        })
      } catch (error) {
        return Promise.reject(error)
      }
      if (this.softInstallData.length === 0) {
        this.status.push('无软件需要安装')
        return
      }
      this.status.push('软件安装')
      // 软件安装
      let i = 0
      while (i < this.softInstallData.length) {
        this.status.push('安装软件: ' + this.softInstallData[i].softVersion)
        try {
          let reData = await serverAppInstallRequest.install({
            installConfig: null,
            serverId: this.softInstallData[i].serverId,
            installTypeId: this.softInstallData[i].installTypeId
          })
          this.status.push('安装结果:', reData.result)
          this.status.push('软件安装' + (reData.success ? '成功' : '失败') + this.softInstallData[i].softVersion)
          if (!reData.success) {
            throw(new Error('软件安装失败: ' + this.softInstallData[i].softVersion))
          }
        } catch (error) {
          if (error.message !== '已安装该软件') {
            throw(error)
          } else {
            this.status.push('已安装该软件')
          }
        }
        i += 1
      }
      this.status.push('软件安装完成')
    },
    async appServiceStart () {
      this.status.push('启动服务')
      let i = 0
      while (i < this.appServiceConfigList.length) {
        this.status.push('启动服务: ' + this.appServiceConfigList[i].appName + ' ' + this.appServiceConfigList[i].restartTypeName)
        let redata = await deployRequest.ur(this.appServiceConfigList[i].id, this.appServiceConfigList[i].file)
        this.status.push('服务启动' + (redata.success ? '成功' : '失败') + ': ' + this.appServiceConfigList[i].appName + ' ' + this.appServiceConfigList[i].restartTypeName)
        if (!redata.success) {
          throw(new Error('服务启动失败: ' + this.appServiceConfigList[i].appName + ' ' + this.appServiceConfigList[i].restartTypeName))
        }
        i += 1
      }
      this.status.push('服务启动完成')
    }
  }
}
</script>

<style lang="less" scoped>
.type-item-title {
  display: flex;
  padding: 0 1.8rem;
  font-size: 1.1rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  color: #1890ff;
}
.deploy-info {
  margin: 0rem 3rem;
  border: 1px solid #dcdee2;
  padding: 1rem;
  position: relative;
}
.no-data {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>