<template>
  <div class="tree-page">
    <div class="tree-info">
      <step :steps="steps"></step>
    </div>
    <div class="page-content">
      <div class="c-item" v-if="steps[0].status !== 'wait'">
        <sys-version :step="steps[0]" :sys-version="sysVersion"></sys-version>
      </div>
      <div class="c-item" v-if="steps[1].status !== 'wait'">
        <sys-instance :step="steps[1]" :sys-version="sysVersion" @sysInstanceChange="sysInstanceChange"></sys-instance>
      </div>
      <div class="c-item" v-if="steps[2].status !== 'wait'">
        <server :step="steps[2]" @serverListChange="serverListChange"></server>
      </div>
      <div class="c-item" v-if="steps[3].status !== 'wait'">
        <app-service
          :step="steps[3]"
          @appVersionServerListChange="appVersionServerListChange"
          :app-version-list="appVersionList"
          :server-list="serverList">
        </app-service>
      </div>
      <div class="c-item" v-if="steps[4].status !== 'wait'">
        <deploy
          :step="steps[4]"
          :app-version-list="appVersionList"
          @editOver="editOver"
          @deployEnd="deployEnd"
          :server-list="serverList"
          :sys-instance="sysInstance"
          :app-version-server-list="appVersionServerList">
        </deploy>
      </div>
    </div>
  </div>
</template>

<script>
import {
  localstorageHelper
} from '@/fmlib'

import {
  sysVersionRequest,
  appVersionRequest,
  serverConfigRequest,
  appVersionConfigRequest
} from '@/api'

import Step from './sys_deploy/step'
import SysInstance from './sys_deploy/sys_instance'
import Server from './sys_deploy/server'
import AppService from './sys_deploy/app_service'
import Deploy from './sys_deploy/deploy'
import SysVersion from './sys_deploy/sys_version.vue'

export default {
  components: {
    Step,
    SysInstance,
    Server,
    AppService,
    Deploy,
    SysVersion
  },
  data () {
   return {
      steps: [{
        key: 'sys_version',
        label: '系统版本',
        status: 'wait',
        info: []
      },
      {
        key: 'sys_instance',
        label: '基础信息',
        status: 'wait',
        info: []
      },
      {
        key: 'server',
        label: '服务器',
        status: 'wait',
        info: []
      },
      {
        key: 'app_service',
        label: '应用配置',
        status: 'wait',
        info: []
      },
      {
        key: 'deploy',
        label: '部署',
        status: 'wait',
        info: []
      }],
      sysVersion: null,
      sysVersionId: null,
      sysInstance: null,
      serverList: [],
      appVersionList: [],
      appVersionServerList: []
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.sysVersionId) {
      next()
    } else {
      next({name: 'work.sys'})
    }
  },
  activated () {
    this.$store.dispatch('setIsPop', true)
  },
  deactivated () {
    this.$store.dispatch('setIsPop', localstorageHelper.getData('is_pop') !== '0')
  },
  created () {
    this.sysVersionId = Number(this.$router.history.current.query.sysVersionId)
    this.loadSysVersion()
  },
  methods: {
    deployEnd (parm) {
      if (parm.success) {
        this.steps[4].status = 'ok'
      } else {
        this.steps[4].status = 'warning'
        this.steps[4].info = [parm.msg]
      }
    },
    editOver () {
      let i = 0
      while (i < 4) {
        this.steps[i].status = 'ok'
        i += 1
      }
    },
    sysInstanceChange (data) {
      this.sysInstance = data
      if (this.sysInstance) {
        this.serverListChange()
      }
    },
    async serverListChange (data) {
      this.serverList = data || this.serverList
      this.steps[2].status = 'doing'
      this.steps[2].info = []
      if (this.serverList.length === 0) {
        this.steps[2].status = 'warning'
        this.steps[2].info.push('请选择服务器')
      } else {
        // 判断服务器是否有自动部署配置
        let i = 0
        while (i < this.serverList.length) {
          let datas = await serverConfigRequest.get({serverId: this.serverList[i].id})
          if (datas.length === 0) {
            this.steps[2].status = 'warning'
            this.steps[2].info.push('服务器 ' + this.serverList[i].name + ' 无自动部署配置')
          }
          i += 1
        }
      }
      if (this.steps[2].status === 'doing') {
        this.appVersionServerListChange()
      }
    },
    appVersionServerListChange (data) {
      this.appVersionServerList = data || this.appVersionServerList
      this.steps[3].status = 'doing'
      this.steps[3].info = []
      this.appVersionServerList.forEach((item) => {
        if (!item.server) {
          this.steps[3].status = 'warning'
          this.steps[3].info.push('应用' + item.appName + ' 无部署服务器')
        }
        if (!item.restartFile) {
          this.steps[3].status = 'warning'
          this.steps[3].info.push('应用' + item.appName + ' 无部署文件')
        }
      })
      if (this.steps[3].status === 'doing') {
        this.steps[4].status = 'doing'
      } else if (this.steps[3].status !== 'doing') {
        this.steps[4].status = 'wait'
      }
    },
    async loadSysVersion () {
      let data = await sysVersionRequest.get({id: this.sysVersionId})
      if (data.length === 0) {
        this.steps[0].status = 'warning'
        this.steps[0].info = ['未找到系统版本']
      } else {
        this.steps[0].status = 'doing'
        this.sysVersion = data[0]
      }
      this.loadAppVersion()
    },
    async loadAppVersion () {
      let data = await appVersionRequest.getSysDepot({sysVersionId: this.sysVersionId})
      let appVersionConfigDatas = await appVersionConfigRequest.get()
      let dataMap = {}
      data.forEach((item) => {
        if (!dataMap[item.id]) {
          dataMap[item.id] = item
          dataMap[item.id].soft = []
          dataMap[item.id].appVersionConfig = appVersionConfigDatas.filter(v => v.appVersionId === item.id)
          if (dataMap[item.id].appVersionConfig.length === 0) {
            this.steps[0].status = 'warning'
            this.steps[0].info.push('应用 ' + item.appName + item.versionNumber + ' 无自动部署配置，无法进行自动部署')
          }
        }
        if (item.softId) {
          dataMap[item.id].soft.push({
            softId: item.softId,
            installTypeId: item.installTypeId,
            softName: item.softName,
            softVersion: item.softVersion
          })
        }
      })
      this.appVersionList = Object.keys(dataMap).map((v) => {
        return dataMap[v]
      })
      if (this.steps[0].status !== 'warning') {
        this.steps[0].status = 'ok'
        this.steps[1].status = 'doing'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tree-page {
  width: 100%;
  height: calc(100% - 4rem);
  display: flex;
}
.tree-info {
  width: 12rem;
  max-width: 12rem;
  min-width: 12rem;
  height: 100%;
}
.page-content {
  width: calc(100% - 12rem);
  min-width: calc(100% - 12rem);
  max-width: calc(100% - 12rem);
  height: 100%;
  overflow-y: auto;
  background: #fff;
}
.c-item {
  border-bottom: 1px solid #dcdee2;
}
</style>