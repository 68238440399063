<template>
  <div class="step-list">
    <a
      class="step-list-item"
      href="javascript:;"
      v-for="item in steps"
      @click="chooseStep(item)"
      :key="item.key">
      <span>{{item.label}}</span>
      <a class="step-status" :style="getStepStyle(item)" href="javascript:;">{{statusMap[item.status] + (item.info.length > 0 ? '!' : '')}}
        <div class="step-status-info" v-if="item.info.length > 0">
          <div class="info-text" v-for="(item, index) in item.info" :key="index">{{item}}</div>
        </div>
      </a>
    </a>
  </div>
</template>

<script>
const statusColor = {
  'wait': '#65b9f0',
  'doing': '#1e9de1',
  'warning': '#ec9738',
  'ok': '#3cbf50'
}
export default {
  props: {
    steps: {
      type: Array
    }
  },
  data () {
    return {
      statusMap: {
        'wait': '等待',
        'doing': '处理',
        'warning': '异常',
        'ok': '完成'
      }
    }
  },
  methods: {
    getStepStyle (step) {
      return {
        'background': statusColor[step.status]
      }
    },
    chooseStep (step) {
      this.$emit('chooseStep', step)
    }
  }
}
</script>

<style lang="less" scoped>
.step-list-title {
  height: 5rem;
  font-size: 1.2rem;
  display: flex;
  padding-left: 2rem;
  align-items: center;
}
.step-list {
}
.step-list-item {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  border-right: 4px solid rgba(0,0,0,0);
  color: rgba(0,0,0,0.65);
}
.step-list-item-a {
  border-right: 4px solid #1890FF;
  color: #1890FF;
  background: #E6F7FF;
  a {
    color: #1890FF;
  }
}
.step-status {
  background: #999;
  color: #fff;
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  .step-status-info {
    color: rgba(0,0,0,0.65);
    display: none;
    position: absolute;
    left: 30px;
    top: 0;
    background: #e6f7ff;
    z-index: 10;
    border-radius: 0.2rem;
    padding: 1rem;
    .info-text {
      white-space:nowrap;
    }
  }
}
.step-status:hover {
  .step-status-info {
    display: block;
  }
}
</style>