<template>
  <div class="sys-instance">
    <type-item
      :dataParm="{id: sysVersion.id}"
      :config="sysVersionListConfig"
      key="choosed">
    </type-item>
  </div>
</template>

<script>
import TypeItem from '@/components/datainfo/TypeItem'

import {
  sysVersionList
} from '@/data_conf/sys_version'

export default {
  components: {
    TypeItem
  },
  props: {
    sysVersion: {
      type: Object
    }
  },
  provide () {
    return {
      parentLevel: ['sys_instance']
    }
  },
  data () {
    return {
      openDialog: false,
      sysVersionListConfig: sysVersionList({
        title: '部署系统版本',
        titleMenus: [],
        listMenus: []
      })
    }
  }
}
</script>
